import styled from 'styled-components'
import * as Styled from 'style'

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    > h3 {
        margin-bottom: 24px;
    }
`


export const Grid = styled(Styled.Grid)`
    
`